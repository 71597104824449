import React from "react"

const Step = props => {
  return (
    <div className="w-full h-[80px] md:h-auto md:w-1/4 flex flex-row md:flex-col mt-8 first-of-type:mt-0 md:mt-0">
      <div className="relative mb-4">
        {props.index > 0 && (
          <div
            className={`absolute flex align-center items-center align-middle content-center w-[8px] left-[50%] h-[90%] top-[-50%] md:w-[calc(100%-5rem)] md:left-0 md:h-0 md:top-[50%] transform translate-x-[-50%] translate-y-[-50%] `}
          >
            <div className="w-full h-full bg-white rounded items-center align-middle align-center flex-1">
              <div className="w-full h-full bg-primary py-1 rounded"></div>
            </div>
          </div>
        )}
        <div className="w-12 h-12 md:w-16 md:h-16 mx-auto bg-primary rounded-full text-lg text-white flex items-center">
          <span className="text-center text-white w-full">
            {props.stepInfo.icon}
          </span>
        </div>
      </div>

      <div className="text-center md:text-base flex-1">
        <div className="text-xl">{props.stepInfo.title}</div>
        <div className="text-sm">{props.stepInfo.description}</div>
      </div>
    </div>
  )
}

export default Step
