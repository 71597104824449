import React from "react"
import { useState } from "react"

const Faq = props => {
  const [currentFaqIndex, setCurrentFaqIndex] = useState(null)

  return (
    <div className="py-12 bg-white shadow">
      <div className="container px-3 mx-auto flex flex-wrap items-center">
        <div className="w-full px-3">
          {props.title && (
            <h2 className="my-4 text-4xl text-center text-black font-semibold leading-snug tracking-wider">
              Frequently Asked Questions
            </h2>
          )}

          <div>
            <div className="shadow-md">
              {props.questions.map((question, index) => (
                <div
                  className="tab w-full overflow-hidden border-t"
                  key={index}
                >
                  <p
                    onClick={() =>
                      setCurrentFaqIndex(
                        currentFaqIndex === index ? null : index
                      )
                    }
                    className="block p-5 text-lg font-semibold leading-normal cursor-pointer hover:bg-gray-100"
                  >
                    {question.q}
                  </p>
                  {currentFaqIndex === index && (
                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-primary leading-normal">
                      <p
                        className="p-5 text-lg"
                        dangerouslySetInnerHTML={question.a}
                      ></p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
