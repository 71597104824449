import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

const Functionality = props => {
  return (
    <div className="py-12 px-12 bg-white shadow">
      {props.features.map((feature, i) => (
        <div
          key={i}
          className={
            `container mx-auto py-12 lg:py-32 flex flex-wrap justify-center items-center ` +
            (i > 0 ? "border-t" : "")
          }
        >
          <div className="w-full md:w-1/2 lg:px-12 px-6">
            <h2 className="my-4 text-4xl font-semibold leading-snug tracking-wider">
              {feature.title}
            </h2>
            <div className="leading-normal text-xl leading-normal">
              {feature.content}
            </div>
            <br />
          </div>
          <div
            className={`w-full md:w-1/2 lg:px-12 px-6 ${
              i % 2 === 0 ? "md:order-first" : ""
            } `}
          >
            {feature.gif && (
              <img
                src={feature.gif}
                alt="In the planner mode you can move cargoes between containers easily"
                className="shadow-2xl"
              />
            )}
            {feature.image && (
              <GatsbyImage
                image={feature.image.childImageSharp.gatsbyImageData}
                alt={feature.image.base.replace(/-/g, " ").split(".")[0]}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

Functionality.defaultProps = {
  features: [],
}

export default Functionality
