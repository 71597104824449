import React from "react"

import {
  BadgeCheckIcon,
  CheckIcon,
  ShareIcon,
  CogIcon,
  CubeIcon,
} from "@heroicons/react/outline"
import Layout from "../components/layout"
import Step from "../components/step"
import SEO from "../components/seo"
import Features2 from "../components/features"
import Faq from "../components/faq"
import FeaturesWithImages from "../components/features_with_images"
import Modules from "../components/modules"
import SignupBanner from "../components/signupBanner"
import PlannerGif from "../images/screens/interactive-planner-mode.gif"
import CalendarView from "../components/calendar-view"
import { graphql, Link } from "gatsby" // to query for image data

const ProductPage = ({ data }) => {
  const steps = [
    {
      title: "1. Add cargo",
      description: (
        <span>
          Import your cargo dimensions via <b>Excel</b>, <b>API</b>,{" "}
          <b>Cargo Library</b> or enter manually.
        </span>
      ),
      icon: <CubeIcon className="h-10 w-10 mx-auto" />,
    },
    {
      title: "2. Select equipment",
      description: (
        <span>
          Select one or <b>multiple</b> containers, trucks and pallets.
        </span>
      ),
      icon: <CogIcon className="h-10 w-10 mx-auto" />,
    },
    {
      title: "3. Done!",
      description: (
        <div>
          <span>
            The software finds an optimal packing solution and combination of
            equipment. The result can <b>easily be modifed</b>.
          </span>
        </div>
      ),
      icon: <BadgeCheckIcon className="h-10 w-10 mx-auto" />,
    },
    {
      title: "4. Share",
      description: (
        <span>
          <b>Print</b>, <b>download</b> or <b>share</b> your load plan with
          others.
        </span>
      ),
      icon: <ShareIcon className="h-10 w-10 mx-auto" />,
    },
  ]

  return (
    <Layout>
      <SEO title="Features" />
      <div className="container mx-auto py-6 px-6  mt-8 mb-12" id="about">
        <h2 className="text-center text-4xl text-black font-semibold leading-snug tracking-wider">
          As simple as it gets!
        </h2>
        <p className="text-center text-gray-700 font-medium tracking-wider">
          Get started with Cargo-Planner's <b>web-based</b> software with{" "}
          <b>4 easy steps</b>:
        </p>
        <div className="flex mt-12 flex-col md:flex-row">
          {steps.map((stepInfo, index) => (
            <Step stepInfo={stepInfo} index={index} key={index} />
          ))}
        </div>
      </div>
      <Modules />
      <CalendarView />
      <FeaturesWithImages
        features={[
          {
            title: "Planner mode",
            gif: PlannerGif,
            content: (
              <span>
                <ul className="text-gray-700 text-xl leading-loose mt-3">
                  <li className="flex">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">
                      <b>Move between containers</b> - Drag a cargo from one
                      container to another to a designated position.
                    </span>
                  </li>
                  <li className="flex mt-6">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">
                      <b>Move within the same container</b> - Change the order
                      and position of a cargo by moving the corresponding card
                      up and down in the list.
                    </span>
                  </li>
                  <li className="flex mt-6">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">
                      <b>Unload cargoes</b> - Easily remove a cargo from a
                      container if you whish to short ship the cargo.
                    </span>
                  </li>
                  <li className="flex mt-6">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">
                      <b>Load from unloaded</b> - Drag a cargo from your list of
                      unloaded cargoes into the designated container at the
                      designated position.
                    </span>
                  </li>
                </ul>
              </span>
            ),
          },
          {
            title: "Load on pallets",
            image: data.pallets,
            content: (
              <span>
                If your cargoes are not palletized, you can select a pallet type
                and then load your cargoes on pallets before loading them on
                containers, trailers (or even other pallets!). Pallets do not
                need to be pallets - you can also create custom crates and boxes
                for your cargoes.
                <br />
                <br />
                You can even load "out of gauge" cargoes on pallets.
              </span>
            ),
          },
          {
            title: "Give your final touch",
            image: data.modify,
            content: (
              <span>
                We still believe that a human stowage planner has a better eye
                than a computer at determining if a load plan is good to go.
                Therefore, modifying the result is extremely easy
                <ul className="text-gray-700 text-xl leading-loose mt-3">
                  <li className="flex">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">
                      Easily convert a loaded container to another type
                    </span>
                  </li>
                  <li className="flex mt-2">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">
                      Remove containers and / or add empty ones
                    </span>
                  </li>
                  <li className="flex mt-2">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">
                      Move around the cargoes with your mouse or keyboard
                    </span>
                  </li>
                </ul>
              </span>
            ),
          },
          {
            title: "Three different modes",
            image: data.fillers,
            content: (
              <span>
                1. If you have a fixed quantity of each item to be loaded - our
                load planning tool will{" "}
                <strong>minimize the containers needed</strong>.
                <br />
                2. If no item has a fixed quantity, the selected container(s)
                will be filled with the different items in a way that
                <strong> utilizes the container in the best way.</strong>
                <br />
                3. If some items has a fixed quantity and some does not - then
                the latters will act as "fillers" which means they will fill the
                containers needed for the other items
              </span>
            ),
          },
          {
            title: "Create custom containers",
            image: data.containerbuilder,
            content: (
              <span>
                CargoPlanner has a built in{" "}
                <Link
                  to="/equipment-library/"
                  className="hover:underline text-blue-900"
                >
                  library
                </Link>{" "}
                with most of the commonly used containers, trailers and pallets.
                If you cannot find the one you are looking for it is very easy
                to create your own type through our interactive container
                builder, where you can
                <ul className="text-gray-700 text-xl leading-loose mt-3">
                  <li className="flex">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">
                      Add / remove roofs, end and side walls
                    </span>
                  </li>
                  <li className="flex mt-2">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">Add doors</span>
                  </li>
                  <li className="flex mt-2">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">
                      Set front and rear axle sets with variable number of axles
                    </span>
                  </li>
                  <li className="flex mt-2">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">Add Corner posts</span>
                  </li>
                  <li className="flex mt-2">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">Add Top frames</span>
                  </li>
                  <li className="flex mt-2">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">Add Contours</span>
                  </li>
                  <li className="flex mt-2">
                    <CheckIcon className="flex-none w-8 h-8 mr-2 text-primary"></CheckIcon>
                    <span className="leading-normal">
                      Add front and rear decks as well as extra floor
                    </span>
                  </li>
                </ul>
              </span>
            ),
          },
          {
            title: "Cargo Library",
            image: data.cargoLibrary,
            content: (
              <span>
                Add items from your product catalogue to the Cargo Library. When
                creating Load plans, simply import the items you want with a
                single click.
                <br />
                <br />
                You can import items both from Excel files, and using our API -
                which you can use to keep the items in sync with your own
                database
              </span>
            ),
          },
          {
            title: "Segregation table",
            image: data.segregationTable,
            content: (
              <span>
                Do you have items that cannot be stored together? No problem,
                just set up your own segregation table to make sure that
                incompatible item types are loaded in different containers.
                <br />
                <br />
              </span>
            ),
          },
          {
            title: "Set container properties on the fly",
            image: data.modifyProperties,
            content: (
              <span>
                You can select multiple container types for each load plan and
                Cargo-Planner will try to select the most appropriate one to fit
                your cargoes. Do adjustments on the fly like Max Loading Height,
                Payload, Max Volume etc...
              </span>
            ),
          },
          {
            title: "Load configurations",
            image: data.loadConfiguration,
            content: (
              <span>
                If you often use a load setup, like containers used, pallet
                configurations and load settings - you can save these as load
                configurations - which you can import for future load plans
              </span>
            ),
          },
          {
            title: "Step by Step instructions",
            image: data.stepByStep,
            content: (
              <span>
                When you are happy with your load plan, you can print it with
                step by step instructions - how to load the individual cargoes.{" "}
                <br />
                <br />
                When you have lots of items, you can also group the instructions
                by a number, or by layer-by-layer
              </span>
            ),
          },
          {
            title: "Load by Priority",
            image: data.priority,
            content: (
              <span>
                You can assign priorities to cargoes to make sure they are
                loaded in a certain order. You can also assign Consignment IDs
                to make sure consignments are not splitted in different
                containers
              </span>
            ),
          },
          {
            title: "Share your load plan",
            image: data.share,
            content: (
              <span>
                With a click you can make the load plan shared, meaning you will
                get a link which you can share to your customers, clients or
                warehouse.
              </span>
            ),
          },
          {
            title: "Support for multiple shapes",
            image: data.cylinders,
            content: (
              <span>
                Cylindrical cargoes like paper reels are hard to do load
                planning for - especially when they have mixed diameters.
                Cargo-Planner will find an optimized solutions even if you add
                pipes and boxes.
              </span>
            ),
          },
        ]}
      ></FeaturesWithImages>
      <Features2></Features2>
      <Faq
        title="Frequently Asked Questions"
        questions={[
          {
            q: "Do you support Out of Gauge cargoes?",
            a: {
              __html:
                "Yes we do! With our Open tops, Flatracks, Platforms and MAFIs for the ocean containers, Flatbeds and Lowbeds for the road, it works as smoothly as with normal cargo. And if you can not find the particular container you are looking for - it is easy to create a customized one!",
            },
          },
          {
            q: "How to get my packlists into Cargo-Planner?",
            a: {
              __html:
                "Most users use our Packlist reader, which works with most layouts of Excel files, but also PDF files.<br /><br/> You can also select the desired rows and columns straight from Excel or an email, and paste it straight into our tool.<br /><br /> If you have your own internal system containing all the data, it is easy to integrate with our API and make the import process running even smoother.<br /><br /> ... And yes, if you prefer to enter the cargoes one at a time, it works as well",
            },
          },
          {
            q: "My cargo has some special loading rules!",
            a: {
              __html:
                "Most of the common rules are already available (like stacking, rotations, layers, lash-space etc). If you miss any, just tell us",
            },
          },
          {
            q: "Is there a Cargo Library?",
            a: {
              __html:
                "Yes! Using our Cargo Library you can upload your cargoes once and then import them whenever you need thems",
            },
          },
          {
            q: "What about performance?",
            a: {
              __html:
                "You might think the performance will be affected in a negative way running this in a web browser instead of using an on premise installation?<br /><br />Performance has always been important for us! All calcululations are done at our own super fast servers, meaning it will actually run faster than on a traditional on premise installation - and it will not hog your computer while doing a heavy calculation.",
            },
          },
          {
            q: "Do you support imperial units?",
            a: {
              __html:
                "We support all of them! You can choose to use metric or imperial units - both when uploading a packing list and working with the load plans",
            },
          },
        ]}
      ></Faq>
      <SignupBanner></SignupBanner>
    </Layout>
  )
}

export const query = graphql`
  {
    pallets: file(
      relativePath: { eq: "containers/container-with-pallets.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
      }
    }
    modify: file(
      relativePath: { eq: "containers/modify-cargo-in-trailer.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    fillers: file(relativePath: { eq: "containers/aaf-with-fillers.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    containerbuilder: file(
      relativePath: {
        eq: "screens/create-your-own-custom-trailers-with-the-container-builder.png"
      }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    cargoLibrary: file(
      relativePath: { eq: "screens/import_items_from_cargo_library.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    segregationTable: file(
      relativePath: { eq: "screens/segregation-table.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    modifyProperties: file(
      relativePath: { eq: "screens/set-properties-on-the-fly.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    loadConfiguration: file(
      relativePath: { eq: "screens/custom-load-configurations.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    stepByStep: file(
      relativePath: { eq: "screens/step-by-step-loading-instructions.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    priority: file(
      relativePath: { eq: "containers/load-by-priority-or-item-groups.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    share: file(
      relativePath: { eq: "screens/share-loadplan-with-a-click.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    cylinders: file(relativePath: { eq: "containers/mixed-item-types.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

export default ProductPage
