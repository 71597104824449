import React from "react"

import {
  AcademicCapIcon,
  AdjustmentsIcon,
  ShareIcon,
  CursorClickIcon,
  CodeIcon,
  CurrencyDollarIcon,
  TruckIcon,
  CubeIcon,
} from "@heroicons/react/outline"
import { Link } from "gatsby"

const Modules = ({}) => {
  return (
    <div className="py-12 px-12 shadow" id="features">
      <h2 className="text-center mb-4 text-4xl text-black font-semibold tracking-wider">
        Why Cargo-Planner?
      </h2>
      <p className="text-center text-gray-700 font-medium tracking-wider">
        There are many reasons why companies choose our solution, and even
        switching from existing ones!
      </p>

      <div className="container mx-auto py-12 flex flex-wrap">
        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <CubeIcon className="h-16 w-16 mx-auto mb-4 text-primary" />
          <p className="font-bold text-xl">Packlist Reader</p>
          <p className="text-gray-700 leading-loose text-lg">
            It is very easy to import your data into Cargo-Planner. Our packlist
            reader handles all kinds of Excel formats - and no template is
            needed.
          </p>
        </div>

        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <CursorClickIcon className="h-16 w-16 mx-auto mb-4 text-primary" />
          <p className="font-bold text-xl">Drag & Drop</p>
          <p className="text-gray-700 leading-loose text-lg">
            Move cargoes around in the containers, un load cargoes from
            containers or move them between containers - all using drag and drop
          </p>
        </div>

        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <AcademicCapIcon className="h-16 w-16 mx-auto mb-4 text-primary" />
          <p className="font-bold text-xl">User Friendly</p>
          <p className="text-gray-700 leading-loose text-lg">
            Get started at once. It is web-based, and no installation is needed.
            Also - it is super easy to use, and don't require any training
          </p>
        </div>

        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <CodeIcon className="h-16 w-16 mx-auto mb-4 text-primary" />
          <p className="font-bold text-xl">Rest API</p>
          <p className="text-gray-700 leading-loose text-lg">
            Easily jack in your own application(s) to our easy to use Rest API.
            In this way, loadplans can be created with just a click!
          </p>
        </div>

        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <CurrencyDollarIcon className="h-16 w-16 mx-auto mb-4 text-primary" />
          <p className="font-bold text-xl">Cost Control</p>
          <p className="text-gray-700 leading-loose text-lg">
            Provide a cost per container type - and/or a revenue per cargo, and
            Cargo-Planner will calculate the most profitable option for you!
          </p>
        </div>

        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <AdjustmentsIcon className="h-16 w-16 mx-auto mb-4 text-primary" />
          <p className="font-bold text-xl">Settings</p>
          <p className="text-gray-700 leading-loose text-lg">
            Fine tune cargo and container settings which are easily accessible -
            resulting in a loadplan according to your preferences
          </p>
        </div>

        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <TruckIcon className="h-16 w-16 mx-auto mb-4 text-primary" />
          <p className="font-bold text-xl">Big library of containers</p>
          <p className="text-gray-700 leading-loose text-lg">
            The most common sea containers, trailers, ULDs and pallets are
            already there. If not - easily add your own! Go and{" "}
            <Link
              to="/equipment-library/"
              className="hover:underline text-blue-900"
            >
              have a look.
            </Link>
          </p>
        </div>

        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <ShareIcon className="h-16 w-16 mx-auto mb-4 text-primary" />
          <p className="font-bold text-xl">Share</p>
          <p className="text-gray-700 leading-loose text-lg">
            Export your load plans to Excel, PDF, JSON or through a sharable
            link
          </p>
        </div>
      </div>
    </div>
  )
}

export default Modules
